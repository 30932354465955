document.addEventListener("DOMContentLoaded", ()=>{
  const topBarIntID = setInterval(() => {
    var topBarOriginal= document.querySelector("#fsb_container");
    if(topBarOriginal){
      clearInterval(topBarIntID);
      changeTopBar(topBarOriginal);
    }
  }, 100);

  function changeTopBar(topBarOriginal) {
    topBarOriginal.insertAdjacentHTML("afterend",'<div id="fsb_fi_container" class="anim-hidden" style="color: inherit;position: absolute;overflow: hidden;"><div id="fsb_fi_background" style="opacity: 1;margin: 0px;padding: 0px;left: 0px;height: auto;width: 100%;z-index: 1000000;position: fixed;top: 0px;"><div id="fsb_fi_bar" style="text-align: center; margin: 0px; padding: 12px 10px; left: 0px; height: auto; width: 100%; box-sizing: border-box; border: none; background-color: rgb(30, 30, 32); color: rgb(255, 255, 255); font-size: 16px; line-height: 20px; font-family: Roboto;">Order 3 or more tubs of SJ Shred and get a free shaker!</div></div></div>')
    var topBarNew = document.querySelector('#fsb_fi_container');

    var barsList = [topBarOriginal, topBarNew];
    var barsActiveIndex = 0;

    setTimeout(()=>{
      var topBarNewChild = topBarNew.querySelector("#fsb_fi_bar");
      var topBarOriginalChild = topBarOriginal.querySelector("#fsb_bar");
      
      topBarNewChild.style.height = topBarOriginal.style.height;
      topBarNewChild.style.fontSize = topBarOriginalChild.style.fontSize;
      topBarNewChild.style.padding = topBarOriginalChild.style.padding;
      topBarNewChild.style.lineHeight = topBarOriginalChild.style.lineHeight;
    }, 7500);

    const transitionIntID = setInterval(() => {
      barsList[barsActiveIndex].classList.add("anim-hide");
      barsList[(barsActiveIndex + 1) % 2].classList.remove("anim-hidden");
      
      setTimeout(()=>{
        barsList[barsActiveIndex].classList.add("anim-hidden");
        barsList[barsActiveIndex].classList.remove("anim-hide");
        
        barsActiveIndex = (barsActiveIndex + 1) % 2;
      }, 1000);

    }, 15000);
  }
});